import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-place',
  templateUrl: './select-place.component.html',
  styleUrls: ['./select-place.component.scss'],
})
export class SelectPlaceComponent implements OnInit {

  @Input() places : any[] = [];

  search : string;
  dataOriginal : any[] = [];
  firstIntent : number = 0;
  value : string;


  constructor(private modalController : ModalController) { }

  ngOnInit() {}

  searching() {
    if(this.firstIntent == 0) {
      this.dataOriginal = this.places;
      this.firstIntent ++;
    }
    if(!this.search || this.search == "") return this.places = this.dataOriginal
    else this.places = this.places.filter((item) => item.includes(this.search.toUpperCase()) )
  }

  async saveData(){
    const modal = await this.modalController.getTop();
    if(modal) {
      this.modalController.dismiss({
        dataSelected : this.value
      })
    }
    
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse, Employee, Visit } from 'src/app/interfaces/apiResponse';
import { environment } from 'src/environments/environment';
import { LoginService } from '../user/login.service';

const token = localStorage.getItem('token');

@Injectable({
  providedIn: 'root',
})
export class GestionadorService {
  constructor(private http: HttpClient, private loginService: LoginService) {}

  token : string;
  ngDoCheck() {
    this.token = this.loginService.token;
  }



  verifyVisit(dniNumber: string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    const dniN: number = +dniNumber;
    const headers = { Authorization:   `Bearer  ${tokenId}` };
    const body = {
      dni: dniN,
      terminal: localStorage.getItem('pc'),
      userCajero: localStorage.getItem('userPc') || "user",
  };
    return this.http
      .post<any>(
        `${environment.apiUrlBase}/${environment.orchestrator}/verifyVisit`,
        body,
        { headers }
      );
  }

  getListVisit(vigente: string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.get<apiResponse>(
      `${environment.apiUrlBase}/${environment.orchestrator}/listVisits?isVigente=${vigente}`,
      {
        headers: {
          Authorization: `Bearer  ${tokenId}`
        },
      }
    );
    // return this.http.get(`${environment.apiUrlBase}/${orchestrator}/listVisits?gestionador=${gestionador}`);
  }

  saveNewMassiveVisit(data: any) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/addVisitMasive`,data,{
      headers : {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }


  getDataTable(company : string,approved : string,page : number) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.get<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/listSupplierEmployeeByCompany?company=${company}&approved=${approved}&page=${page}`,{
      headers : {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  approvveEmployee(id : string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.get<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/approveSupplierEmployee?idSupplierEmployee=${id}`,{
      headers : {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  deleteEmployeesMasive(vectorId : string[]) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/deleteSupplierEmployeeMasive`,vectorId,{
      headers : {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  approvveEmployeeMasive(vectorId : string[]) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/approveSupplierEmployeeMasive`,vectorId,{
      headers: {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  blackList(area : string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.get<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/listBlacklist?area=${area}&page=0`,{
      headers: {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  addEmployeesToblackList(vectorEmployee:any[],area : string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    const body = {
      area,
      list:vectorEmployee
    };
    console.log("Body: ",body)
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/addBlacklistMasive`,body,{
      headers: {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }

  deleteEmployeesFromblackList(vectorEmployee:any[]) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/deleteBlacklistMasive`, vectorEmployee, {
      headers: {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }


  renewSupplierEmployeeMasive(vectorId : String[], newExpireDate: number) {
    this.token = this.loginService.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.http.post<apiResponse>(`${environment.apiUrlBase}/${environment.orchestrator}/renewSupplierEmployeeMasive?validPeriod=${newExpireDate}`,vectorId,{
      headers: {
        Authorization : `Bearer  ${tokenId}`
      }
    })
  }
}

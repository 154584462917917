import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Button } from 'src/app/interfaces/apiResponse';
import { ColumnMode, SelectionType, SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-black-list-component',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.scss'],
})
export class BlackListComponent implements OnInit {

  @Input() data : any[] = [];
  @Input() buttons : Button[] = [];
  @Output() wotkData : EventEmitter<any[]> = new EventEmitter();

  columns = [
    { name: 'Nombre', prop:"nameEmployee" },
    { name: "Apellido", prop:"lastnameEmployee" },
    { name : "Area"},
    { name: "Dni", prop: "dniEmployee" }
  ];
  selected : any[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  SortType = SortType;

  textFilter : string;
  originalData : any[] = [];
  firstCount : number = 0;


  constructor() { }

  ngOnInit() {}


  onSelect(selected : any) {
    this.selected = selected.selected;
  }

  displayCheck: (row: any, column?: any, value?: any) => boolean;


  editEmployee(row : any) {
    
  }

  changeFilterText() {
    this.selected = [];
    if(this.firstCount === 0) {
      this.originalData = this.data
      this.firstCount ++;
    }
    console.log(this.originalData);
    let regNum = /[^A-Za-z0-9/]+/g;
    this.textFilter = this.textFilter.toUpperCase();
    if (!regNum.test(this.textFilter) && this.textFilter.length !== 0) {
      let newRows = this.data;
      this.data = newRows.filter((employee) => {
        let dni = employee.dniEmployee.toString();
        if (
          employee.nameEmployee.includes(this.textFilter) ||
          employee.lastnameEmployee.includes(this.textFilter) ||
          employee.area.includes(this.textFilter) ||
          dni.includes(this.textFilter)
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return this.data = this.originalData;
    }

    console.log(this.originalData)
  }


  functionEmiter(button : Button) {
    let vectorData =  [];
    vectorData = [...vectorData,button.functionName];
    vectorData = [...vectorData,this.selected];
    this.selected = [];
    console.log("Vector Data:", vectorData)
    this.wotkData.emit(vectorData)
  }

}

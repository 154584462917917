import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { GestionadorService } from 'src/app/services/orchestrator/gestionador.service';

@Component({
  selector: 'app-extend-time-component',
  templateUrl: './extend-time-component.component.html',
  styleUrls: ['./extend-time-component.component.scss'],
})
export class ExtendTimeComponentComponent implements OnInit {
  @Output() changeExpirationDateTrigger: EventEmitter<string> = new EventEmitter<string>();
  @Input() data: String[];
  currentDate = new Date().toISOString();
  newExpireDate: any;
  message: string;
  constructor(private gestionadorService: GestionadorService) {}

  ngOnInit() {}

  changeDate() {
    if (
      this.newExpireDate !== '' &&
      this.newExpireDate !== this.currentDate &&
      this.newExpireDate !== undefined
    ) {
      let today = new Date();
      let newExpDate = new Date(this.newExpireDate);
      let diffInTime = newExpDate.getTime() - today.getTime();
      let diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));
      console.log(diffInDays);
      this.gestionadorService
        .renewSupplierEmployeeMasive(this.data, diffInDays)
        .subscribe(
          (res) => {
            this.changeExpirationDateTrigger.emit('success');
          },
          (error) => {
            this.changeExpirationDateTrigger.emit('error');
          }
        );
    } else {
      return;
    }
  }
}

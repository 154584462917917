import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { GestionadorGuard } from './guard/enter/gestionador.guard';
import { ProveedorGuard } from './guard/enter/proveedor.guard';
import { SeguridadGuard } from './guard/enter/seguridad.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login/user/pc',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   redirectTo: 'login/user/pc'
  // },
  {
    path: 'login/:user/:pc',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'proveedor-home',
    loadChildren: () => import('./proveedor-home/proveedor-home.module').then( m => m.ProveedorHomePageModule),
    canLoad : [ AuthGuard ],
    canActivate: [ ProveedorGuard ],
    
    
  },
  {
    path: 'cajero-home',
    loadChildren: () => import('./cajero-home/cajero-home.module').then( m => m.CajeroHomePageModule)
  },
  {
    path: 'gestionador-home',
    loadChildren: () => import('./gestionador-home/gestionador-home.module').then( m => m.GestionadorHomePageModule),
    canLoad : [ AuthGuard ],
    canActivate : [ GestionadorGuard ] 
  },
  {
    path: 'seguridad-home',
    loadChildren: () => import('./seguridad-home/seguridad-home.module').then( m => m.SeguridadHomePageModule),
    canLoad : [ AuthGuard],
    canActivate : [ SeguridadGuard ]
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

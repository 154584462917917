import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'src/app/interfaces/apiResponse';
import { environment } from 'src/environments/environment';
import { LoginService } from '../user/login.service';

const token = localStorage.getItem('token');


@Injectable({
  providedIn: 'root'
})
export class BigDataService {

  token : string;

  constructor(private httpClient : HttpClient,private loginService: LoginService) { }

  saveDocument(data : any) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.httpClient.post<apiResponse>(`${environment.bigData}/saveEmployeeDocumentMasive`,data,{
      headers: {
        Authorization: `Bearer  ${tokenId}`
      },
    })
  }

  getEmployeeDocumentsTitles(employeeId : string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.httpClient.get<apiResponse>(`${environment.bigData}/getEmployeeDocumentTitles?employeeId=${employeeId}`,{
      headers: {
        Authorization: `Bearer  ${tokenId}`
      },
    })
  }

  deleteFiles(deleteSerie : boolean, EmployeeFileIds : string[]) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.httpClient.post<apiResponse>(`${environment.bigData}/deleteEmployeeDocumentMasive`, {
      deleteSerie,
      ids : EmployeeFileIds
    },
    {
      headers: {
        Authorization: `Bearer  ${tokenId}`
      }
    })
  }

  getEmployeeFile(fileId : string) {
    this.token =  this.loginService.dataLogin?.token
    let tokenId : string;
    if(!this.token) tokenId = token
    else tokenId = this.token;
    return this.httpClient.get<apiResponse>(`${environment.bigData}/getEmployeeDocument?fileId=${fileId}`,{
      headers: {
        Authorization: `Bearer  ${tokenId}`
      }
    })
  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ColumnMode, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Button } from 'src/app/interfaces/apiResponse';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() loading: boolean = true;
  @Input() buttons: Button[] = [];
  @Output() wotkData: EventEmitter<any[]> = new EventEmitter();
  @Output() workFiles : EventEmitter<any> = new EventEmitter();
  buttonsTable: Button[] = [];


  columns = [
    { name: 'Personal', prop: 'personal' },
    { name: 'Empresa', prop: 'company' },
    { name: 'Dni' },
    { name: 'Registrado', prop: 'createdAt' },
    { name: 'Estado', prop: 'approved' },
    { name: 'Caducidad', prop: 'currentDaysToExpire' },
    { name: 'Adjunto', prop: 'employeeFilesIds' },
  ];

  selected: any[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  SortType = SortType;
  textFilter: string;
  originalData: any[] = [];
  firstCount: number = 0;

  constructor() {}

  ngOnInit() {
    this.selected = [];
    this.buttonsTable = this.buttons;
  }
  editEmployee(row : any) {}

  displayCheck: (row: any, column?: any, value?: any) => boolean;

  onSelect(selected: any) {
    this.selected = selected.selected;
    if (this.selected.length > 1) {
      this.buttonsTable = this.buttons.filter((button) => {
        return button.functionName !== 'editEmployee()';
      });
    } else {
      this.buttonsTable = this.buttons;
    }
  }

  changeFilterText() {
    this.selected = [];
    if (this.firstCount === 0) {
      this.originalData = this.data;
      this.firstCount++;
    }
    console.log(this.originalData);
    let regNum = /[^A-Za-z0-9/]+/g;
    this.textFilter = this.textFilter.toUpperCase();
    if (!regNum.test(this.textFilter) && this.textFilter.length !== 0) {
      let newRows = this.data;
      this.data = newRows.filter((employee) => {
        let dni = employee.dni.toString();
        if (
          employee.name.includes(this.textFilter) ||
          employee.lastname.includes(this.textFilter) ||
          employee.company.includes(this.textFilter) ||
          dni.includes(this.textFilter) ||
          employee.createdAt.includes(this.textFilter)
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return (this.data = this.originalData);
    }
  }

  functionEmiter(button: Button) {
    let vectorData = [];
    vectorData = [...vectorData, button.functionName];
    vectorData = [...vectorData, this.selected];
    this.selected = [];
    this.wotkData.emit(vectorData);
  }

  workWithFiles(row : any) {
    this.workFiles.emit(row);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/user/login.service';

const role = localStorage.getItem('role');


@Injectable({
  providedIn: 'root'
})
export class SeguridadGuard implements CanActivate {

  constructor(private loginService: LoginService) {}

  role = this.loginService.role;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(role)
      if(this.role == "SEGURIDAD") return true;
      else {
        if(!this.role) {
          if(role == "SEGURIDAD") return true;
        }
      }  
    return false;
  }
  
}

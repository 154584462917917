import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSubscriber } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor() { }


  convertToBase64(file : File) {
    return new Observable((subscriber :SubjectSubscriber<any>)=> {
      this.readFile(file,subscriber);
    })
  }

  private readFile(file : File,subscriber :SubjectSubscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);

    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    }

    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    } 
  }

  decodeFileBase64(dataUrl : any) {
    let data = dataUrl.split(',');
    const byteArrays = [];
    const byteCharacters = atob(data[1])
    for (let offset = 0; offset < byteCharacters.length; offset+= 512) {
      const slice = byteCharacters.slice(offset,offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let index = 0; index < slice.length; index++) {
        byteNumbers[index] = slice.charCodeAt(index)
        
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray)
      
    }

    let type = data[0].split(';')
    let divideType = type[0].split(':');

    const blob = new Blob(byteArrays,{type:divideType[1]});
    return blob;
    
  }

  isControlSizeValid(file : File) {
    let size = file.size;
    console.log(size);
    if(size > 1024000000) return false;
    else return true;
  }

  downloadFile(blob : Blob) {
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'src/app/interfaces/apiResponse';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';


const headers = { Authorization: 'alsjkdhalkjsdh' };

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _role : string;
  private _token : string;
  private _company : string;
  private _area : string;
  _dataLogin : any;

  get role() {
    return this._role
  }

  get token() {
    return this._token
  }

  get company() {
    return this._company
  }

  get area() {
    return this._area;
  }

  setDataLogin(data : any) {
    this._dataLogin = data;
  }

  get dataLogin() {
    return this._dataLogin;
  }





  constructor(private http : HttpClient) { }

  login(user : any) {
    return this.http.post(`${environment.user}/auth/login`,user)
      .pipe(
        tap((res : any) => {
          this._role = res.data.role;
          this._token = res.data.token
          this._company = res.data.company
          this._area = res.data.area
          const {role,token,company,area,username} =  res.data;
          localStorage.setItem('token',token);
          localStorage.setItem('role',role);
          localStorage.setItem('company',company);
          localStorage.setItem('area',area);
          localStorage.setItem('username',username);
        })
      )
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/user/login.service';

const role = localStorage.getItem('role');


@Injectable({
  providedIn: 'root'
})


export class GestionadorGuard implements CanActivate {

  constructor(private loginService : LoginService) {}

  role = this.loginService.role

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log(this.role)
      if(this.role == "GESTIONADOR") return true
      else {
        if(!this.role) {
          if(role == "GESTIONADOR") return true;
        }
      }
      return false
  }
  
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  user :string
  isLogin : boolean = false;
  
  constructor(private router : Router) { }

  ngOnInit() {}

  ngDoCheck() {
    this.user = localStorage.getItem('username');
    if(this.user) this.isLogin = true
    else this.isLogin = false;
  }

  login() {
    localStorage.clear();
    this.router.navigate(['/login/usuario/pc']);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login/usuario/pc']);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ColumnMode, SelectionType, SortType } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.scss'],
})
export class SelectBranchComponent implements OnInit {
  
  @Input() branches : any[] = [];
  @Input() value : string;
  search : string;
  dataOriginal : any[] = [];
  firstIntent : number = 0;

  selected: any[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  SortType = SortType;


  columns = [
    { name: 'Sucursal', prop: 'nombreAgente' },
  ];
  // value : string;

  constructor(private modalController : ModalController) { }

  ngOnInit() {}
  displayCheck: (row: any, column?: any, value?: any) => boolean;


  searching() {
    if(this.firstIntent == 0) {
      this.dataOriginal = this.branches;
      this.firstIntent ++;
    }
    if(!this.search || this.search == "") return this.branches = this.dataOriginal
    else this.branches = this.branches.filter((item) => item.nombreAgente.includes(this.search.toUpperCase()) )
  }

  onSelect(selected: any) {
    this.selected = selected.selected;
  }

  async saveData(){
    const modal = await this.modalController.getTop();
    if(modal) {
      this.modalController.dismiss({
        dataSelected : this.selected
      })
    }
    // modal.dismiss({
    //   dataSelected : this.value
    // })
    
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TableComponent } from './table/table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicModule } from '@ionic/angular';
import { ExtendTimeComponentComponent } from './extend-time-component/extend-time-component.component';
import { FormsModule } from '@angular/forms';
import { BlackListComponent } from './black-list/black-list.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ModalComponent } from './modal/modal.component';
import { SelectBranchComponent } from './select-branch/select-branch.component';
import { SelectPlaceComponent } from './select-place/select-place.component';
import { SelectProvincesComponent } from './select-provinces/select-provinces.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TableComponent,
    ExtendTimeComponentComponent,
    BlackListComponent,
    MultiSelectComponent,
    ModalComponent,
    SelectBranchComponent,
    SelectPlaceComponent,
    SelectProvincesComponent,
  ],
  exports : [
    HeaderComponent,
    TableComponent,
    ExtendTimeComponentComponent,
    BlackListComponent,
    MultiSelectComponent,
    ModalComponent,
    SelectBranchComponent,
    SelectPlaceComponent,
    SelectProvincesComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    NgxDatatableModule,
  ],
  entryComponents: [
    SelectBranchComponent,
    SelectPlaceComponent
  ]
})
export class ComponentsModule { }

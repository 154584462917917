import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {

  @Input() avaibleSelectAll : boolean = false;
  @Input() data : any[] = [];
  @Input() title : string = "";
  @Input() titleList : string = "";
  
  dataOriginal : any[] = [];
  search : string;
  dataSelected : any[] = [];
  firstIntent : number = 0;

  constructor() { }

  ngOnInit() {}

  selectAll() {
    this.data.map((item)=> {
      item.isChecked = !item.isChecked;
    })
  }

  searching() {
    if(this.firstIntent == 0) {
      this.dataOriginal = this.data;
      this.firstIntent ++;
    }
    if(!this.search || this.search == "") return this.data = this.dataOriginal
    else this.data = this.data.filter((item) => item.nombreAgente.includes(this.search.toUpperCase()) )
  }

  addItem(event: any,item : any) {
    if(event.detail.checked) {
      console.log("entro en cheked")
      if(!this.dataSelected.includes(item)) this.dataSelected.push(item)
    }
    else {
      if(this.dataSelected.includes(item)) this.dataSelected = this.dataSelected.filter((data) => data != item)
    }
    console.log(this.dataSelected)
    
  }

  onSearchChange(event : any) {
    console.log(event)
  }

}

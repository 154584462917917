// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlBase : "https://wusecurity.ar/api_backend/ms.orchestrator/security",
  orchestrator : "ms.orchestrator",
  user : "https://wusecurity.ar/api_backend/ms.user/security/ms.user",
  bigData : "https://wusecurity.ar/api_backend/ms.bigdata/security/ms.bigdata",
  //apiUrlBase : "http://localhost:9099/security",
  //orchestrator : "ms.orchestrator",
  //user : "http://localhost:9081/security/ms.user",
  //bigData : "http://localhost:9102/security/ms.bigdata"
  // apiUrlBase : "http://172.16.103.103:9099/security",
  // orchestrator : "ms.orchestrator",
  // user : "http://172.16.103.103:9081/security/ms.user",
  // bigData : "http://172.16.103.103:9102/security/ms.bigdata"
  //apiUrlBase : "https://security.wucenter.ar/ms.orchestrator/security",
  //orchestrator : "ms.orchestrator",
  //user : "https://security.wucenter.ar/ms.user/security/ms.user",
  //bigData : "https://security.wucenter.ar/ms.bigdata/security/ms.bigdata"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

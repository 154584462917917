import { Component, Input, OnInit} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Employee, EmployeeFilesTitles } from 'src/app/interfaces/apiResponse';
import { BigDataService } from 'src/app/services/bigData/big-data.service';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input() employee : Employee;
  files : EmployeeFilesTitles[] = [];
  selected : EmployeeFilesTitles[] = [];
  message : string;

  constructor(
    private bigDataService : BigDataService,
    private modalController : ModalController,
    private alertController : AlertController,
    private fileService : FilesService
  ) { }

  ngOnInit() {
    this.bigDataService.getEmployeeDocumentsTitles(this.employee.id).subscribe(
      res => {
        res.data.map(employee => {
          const data = {
            ...employee,
            isChecked: false
          }
          this.files = [...this.files,data]
        })
        console.log(this.files)
      },
      error => {
        console.log(error)
      }
    )
  }

  selectedAll() {
    this.files.map((file : any) => {
      file.isChecked = !file.isChecked
    })
  }

  selectFile(file : EmployeeFilesTitles){
    if(this.selected.includes(file)) this.selected = this.selected.filter((item) => item.employeeFileId != file.employeeFileId);
    else this.selected = [...this.selected,file];
  }

  dismiss() {
    this.modalController.dismiss({
      refresh: true
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Importante',
      message: this.message,
      buttons : [
        {
          text: "OK",
          handler : () => {
            console.log("")
          }
        }
      ]
    });

    await alert.present();
  }

  delete(serie : boolean) {
    let vectorId = [];
    this.selected.map((employee) => {
      vectorId = [...vectorId,employee.employeeFileId]
    });

    console.log(vectorId)
    this.bigDataService.deleteFiles(serie,vectorId).subscribe(
      res => {
        console.log(res);
        this.message = "Se borraron los archivos";
        this.presentAlert();
        this.dismiss()
      },
      error => {
        console.log(error)
        this.message = "Ocurrio un error";
        this.presentAlert();
        this.dismiss()
      }
    )
  }

  downloadFile(data : EmployeeFilesTitles) {
    this.bigDataService.getEmployeeFile(data.fileId).subscribe(
      res => {
        console.log(res)
        const blob : Blob =  this.fileService.decodeFileBase64(res.data);
        this.fileService.downloadFile(blob);
      }
    )
  }


}
